import router from '@/router'
import dayjs from "dayjs";

export const state = {
    claimId: undefined,
    displayRestartClaimModal: false,
    previousStep: undefined,
    questionnaireFormValid: false,
    reasonFormValid: false,
    summaryQuestionnaireFormValid: false,
    step: 1,
    steps: [],
};

/*
    Actions hook up to the interface, but they do not touch the store data directly.
    They can be rather complicated and will generally lean on one or more mutations.
    Actions are also the communication layer with external sources (API).

    Actions come with access to four contexts ({ state, getters, commit, dispatch })
        state = direct data access... avoid use
        getters = indirect data access... this is good
        commit = call a mutation
        dispatch = for us, dispatch will almost certainly be an axios api call
*/
export const actions = {
    navigateToIntake({ dispatch, getters }) {
        router.push({ path: "/create-claim/" });

        const currentStep = getters.getIntakeNavigationCurrentStep;
        if (currentStep.name === 'accountHolderSearch') {
            dispatch("startIntake");
        } else {
            dispatch("displayRestartClaimModal", true);
        }

    },
    async startIntake({ commit, dispatch, getters }) {
        dispatch("displayRestartClaimModal", false);
        dispatch("clearClaimDetails");
        commit("SET_INTAKE_CLAIM_ID", undefined);

        // handle prepopulated data
        const { AccountId, CustomerId, TransactionList } = getters.getIntakePrepopulatedData;

        let initialStep = 'accountHolderSearch';

        if (CustomerId) {
            initialStep = 'accountSelect';
            dispatch("setCustomer", { customerId: CustomerId });
            dispatch("retrieveAccounts", { CustomerId: CustomerId });
        }
        if (AccountId) {
            initialStep = 'reason';
        }
        if (TransactionList) {
            dispatch("setSelectedTransactions", TransactionList)
        }

        dispatch("setIntakeStep", {
            name: initialStep
        });
        if (AccountId) {
            await dispatch("createClaim", {
                AccountNumber: AccountId,
                CustomerId: CustomerId,
            })
            dispatch("retrieveReasons", {
                ClaimId: getters.getSelectedClaim.data.ClaimId
            });
        }
    },
    cancelIntakeNav({ dispatch, getters }) {
        if (getters.getIntakeNavigationCancelConfirmationSkip) {
            dispatch("clearClaimDetails");
            dispatch("setIntakeStep", { name: 'accountHolderSearch' });
            dispatch("removeNavItem", '/create-claim/');
        } else {
            dispatch("setIntakeStep", {
                name: "cancel"
            })
        }
    },
    resetIntakeSteps({ commit, getters }) {
        commit("UPDATE_STEPS", getters.getIntakeNavigationDefaultSteps);
    },
    setQuestionnaireFormValidity({ commit }, { value }) {

        commit("SET_QUESTIONNAIRE_FORM_VALIDITY", value);
    },
    setReasonFormValidity({ commit }, { value }) {

        commit("SET_REASON_FORM_VALIDITY", value);
    },
    setSummaryQuestionnaireFormValidity({ commit }, { value }) {

        commit("SET_SUMMARY_QUESTIONNAIRE_FORM_VALIDITY", value);
    },
    displayRestartClaimModal({ commit }, value) {
        commit("DISPLAY_RESTART_CLAIM_MODAL", value);
    },

    async intakeNavigationNext({ commit, dispatch, getters }) {
        const currentStep = getters.getIntakeNavigationCurrentStep;

        try {
            switch (currentStep.name) {
                case 'cancel':
                    await dispatch("cancelIntake", {
                        ClaimId: getters.getSelectedClaim.data.ClaimId,
                        CancelClaimReason: getters.getIntakeCancelReason,
                    });
                    break;
                case 'accountHolderSearch':
                    await dispatch("setCustomer", {
                        customerId: getters.getSelectedCustomer[0].CustomerId,
                    });
                    await dispatch("retrieveAccounts", {
                        CustomerId: getters.getSelectedCustomer[0].CustomerId,
                    });
                    break;
                case 'accountSelect':
                    await dispatch("createClaim", {
                        AccountNumber: getters.getSelectedAccount[0].AccountNumber,
                        CustomerId: getters.getIntakeCustomer,
                    });
                    commit("SET_INTAKE_CLAIM_ID", getters.getSelectedClaim.data.ClaimId);
                    await dispatch("retrieveReasons", {
                        ClaimId: getters.getSelectedClaim.data.ClaimId
                    });
                    break;
                case 'reason': {
                    await dispatch("addClaimReasons", {
                        ClaimId: getters.getSelectedClaim.data.ClaimId,
                        answers: getters.getReasonAnswers,
                    });

                    const startDate = dayjs().subtract(15, "days").format("YYYYMMDD");
                    const endDate = dayjs().format("YYYYMMDD");
                    await dispatch("retrieveTransactions", {
                        ClaimId: getters.getSelectedClaim.data.ClaimId,
                        SearchStartDate: startDate,
                        SearchEndDate: endDate,
                    });

                    if (getters.getTransactionsStatus.transactionsListDisplay !== 'hidden') {
                        // add transaction selection to steps
                        const transactionSelectionStep = {
                            parentStep: 3,
                            name: "transactionSelection",
                            hash: "#transactionSelection",
                        };
                        const steps = getters.getIntakeNavigationSteps;
                        const questionnaireStepIndex = steps.findIndex(step => step.name === "reason");
                        // insert transaction selection after reason
                        const newSteps = [
                            ...steps.slice(0, questionnaireStepIndex + 1),
                            transactionSelectionStep,
                            ...steps.slice(questionnaireStepIndex + 1)
                        ];
                        commit("UPDATE_STEPS", newSteps);
                    } else {
                        dispatch("retrieveQuestionnaire", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                        })
                    }
                    break;
                }
                case 'transactionSelection':
                    if (getters.getSelectedTransactions.length > 0) {
                        await dispatch("addClaimTransactions", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                            transactions: getters.getSelectedTransactions,
                        });
                    }
                    dispatch("retrieveQuestionnaire", {
                        ClaimId: getters.getSelectedClaim.data.ClaimId,
                    })
                    break;
                case 'questionnaire':
                    if (getters.getSimilarSelectedTransactions.length > 0) {
                        await dispatch("addSimilarTransactions", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                            transactions: getters.getSimilarSelectedTransactions,
                        });
                    }
                    if (getters.getRecognizedTransactions.length > 0) {
                        await dispatch("recognizeDisputedTransactions", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                            transactions: getters.getRecognizedTransactions,
                        });
                    }
                    if (getters.getCheckDeposits.length > 0) {
                        await dispatch("addCheckDeposits", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                            CheckDepositList: getters.getCheckDeposits,
                        });
                    }
                    if (getters.getQuestionnaireFormValidity) {
                        await dispatch("addQuestionnaire", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                            answers: getters.getQuestionnaireAnswers,
                        });
                    }
                    await dispatch("retrieveDocRequirements", {
                        ClaimId: getters.getSelectedClaim.data.ClaimId,
                    });
                    if (getters.getDocRequirements) {
                        // add doc requirements to steps
                        const requiredDocsStep = {
                            parentStep: 3,
                            name: "requiredDocs",
                            hash: "#requiredDocs",
                        };
                        const steps = getters.getIntakeNavigationSteps;
                        const questionnaireStepIndex = steps.findIndex(step => step.name === "questionnaire");
                        // insert doc requirements after questionnaire
                        const newSteps = [
                            ...steps.slice(0, questionnaireStepIndex + 1),
                            requiredDocsStep,
                            ...steps.slice(questionnaireStepIndex + 1)
                        ];
                        commit("UPDATE_STEPS", newSteps);
                    }
                    break;
                case 'requiredDocs':
                    break;
                case 'summaryQuestionnaire':
                    if (getters.getSummaryQuestionnaireFormValidity) {
                        const correspondenceDetails = () => {
                            const isSendCurrentInfo = getters.getSummarySendInfo === "Yes";
                            const correspondenceChannels = getters.getCorrespondenceChannels;

                            const selectedChannel = isSendCurrentInfo ? getters.getSummary.CorrespondenceDetails.SelectedChannel : getters.getSummaryChannel;
                            const address = isSendCurrentInfo ? correspondenceChannels.find(
                                (channel) => channel.Name === "Mail"
                            ).Address : getters.getSummaryAddress;
                            const email = isSendCurrentInfo ? correspondenceChannels.find(
                                (channel) => channel.Name === "Email"
                            ).Address.EmailAddress : getters.getSummaryEmailAddress;

                            return {
                                SelectedChannel: selectedChannel,
                                ...(getters.getSummaryChannel === "Email"
                                    ? { EmailAddress: email }
                                    : { Address: address }),
                            };
                        }

                        await dispatch("intakeSubmit", {
                            ClaimId: getters.getSelectedClaim.data.ClaimId,
                            answers: getters.getSummaryAnswers,
                            CorrespondenceDetails: correspondenceDetails(),
                        });
                    }
                    break;
                case 'confirmation':
                    break;
            }

            await commit("SET_INTAKE_NAVIGATION_PREVIOUS_STEP", getters.getIntakeNavigationStep);
            await commit("SET_INTAKE_NAVIGATION_STEP", getters.getIntakeNavigationStep + 1);
        } catch (error) {
            // don't navigate forward if there is an error
            console.error('Intake Navigation Error', error);
        }
    },
    async intakeNavigationBack({ commit, getters }) {
        // need to consider the cancel screen (step 0).  If on that screen, the back should take to whatever previous step was loaded
        const newStep = getters.getIntakeNavigationStep === 0 ? getters.getIntakeNavigationPreviousStep : getters.getIntakeNavigationStep - 1;

        await commit("SET_INTAKE_NAVIGATION_PREVIOUS_STEP", getters.getIntakeNavigationStep);
        await commit("SET_INTAKE_NAVIGATION_STEP", newStep);
    },
    async setIntakeStep({ commit, getters }, { name }) {
        const stepIndex = getters.getIntakeNavigationSteps.findIndex((step) => step.name === name);
        await commit("SET_INTAKE_NAVIGATION_PREVIOUS_STEP", getters.getIntakeNavigationStep);
        commit("SET_INTAKE_NAVIGATION_STEP", stepIndex);
    },
};

/*
    Mutations are methods that act on the state data directly.
    They should be incredibly basic and perform a single task.

    Mutations always take in the current 'state' and a payload.
*/
export const mutations = {
    DISPLAY_RESTART_CLAIM_MODAL(state, value) {
        state.displayRestartClaimModal = value;
    },
    SET_INTAKE_CLAIM_ID(state, claimId) {
        state.claimId = claimId;
    },
    SET_INTAKE_NAVIGATION_PREVIOUS_STEP(state, previousStep) {
        state.previousStep = previousStep;
    },
    SET_INTAKE_NAVIGATION_STEP(state, step) {
        state.step = step;
    },
    SET_QUESTIONNAIRE_FORM_VALIDITY(state, value) {
        state.questionnaireFormValid = value;
    },
    SET_REASON_FORM_VALIDITY(state, value) {
        state.reasonFormValid = value;
    },
    SET_SUMMARY_QUESTIONNAIRE_FORM_VALIDITY(state, value) {
        state.summaryQuestionnaireFormValid = value;
    },
    UPDATE_STEPS(state, steps) {
        state.steps = steps;
    },
};

/*
    Getters are non-cached access points to the state data.
    They allow us to interrogate the data and pull back dynamic subsets.
*/

export const getters = {
    getIntakeClaim: state => state.claimId,
    getIntakeDisplayRestartClaimModal: state => { return state.displayRestartClaimModal },
    getIntakeNavigationPreviousStep: state => {
        if (Number.isInteger(state.previousStep)) {
            return state.previousStep;
        }
        return 1;
    },
    getIntakeNavigationStep: state => {
        if (Number.isInteger(state.step)) {
            return state.step;
        }
        return 1;
    },
    getIntakeNavigationSteps: state => { return state.steps },
    getIntakeNavigationCurrentStep: (state, getters) => { return getters.getIntakeNavigationSteps[getters.getIntakeNavigationStep] },
    getIntakeNavigationBackButtonHidden: (state, getters) => { return getters.getIntakeNavigationCurrentStep && getters.getIntakeNavigationCurrentStep.hideBackButton || false },
    getIntakeNavigationCancelButtonHidden: (state, getters) => { return getters.getIntakeNavigationCurrentStep && getters.getIntakeNavigationCurrentStep.hideCancelButton || false },
    getIntakeNavigationCancelConfirmationSkip: (state, getters) => { return getters.getIntakeNavigationCurrentStep && getters.getIntakeNavigationCurrentStep.skipCancelConfirmation || false },
    getIntakeNavigationDefaultSteps: (state, getters) => {
        return [
            {
                parentStep: 0,
                name: "cancel",
                hideBackButton: true,
                hideCancelButton: true,
                nextButtonLabel: 'Yes, Cancel',
            },
            {
                parentStep: 1,
                name: "accountHolderSearch",
                hideBackButton: true,
                skipCancelConfirmation: true,
            },
            {
                parentStep: 1,
                name: "accountSelect",
                hash: "#accountSelect",
                hideBackButton: getters.getIntakeNavigationHideCustomerSelect,
                skipCancelConfirmation: true,
            },
            {
                parentStep: 2,
                name: "reason",
                hideBackButton: getters.getIntakeNavigationHideAccountSelect,
            },
            {
                parentStep: 3,
                name: "questionnaire",
                hash: "#questionnaire",
            },
            {
                parentStep: 4,
                name: "summaryQuestionnaire",
            },
            {
                parentStep: 4,
                name: "confirmation",
                hash: "#confirmation",
                hideBackButton: true,
                hideCancelButton: true,
                hideNextButton: true,
                skipCancelConfirmation: true,
            },
        ];
    },
    getIntakeNavigationHideAccountSelect: (state, getters) => { return getters.getIntakePrepopulatedData && getters.getIntakePrepopulatedData.AccountId || false },
    getIntakeNavigationHideCustomerSelect: (state, getters) => { return getters.getIntakePrepopulatedData && getters.getIntakePrepopulatedData.CustomerId || false },
    getIntakeNavigationNextButtonDisabled: (state, getters) => {
        const currentStep = getters.getIntakeNavigationCurrentStep;

        switch (currentStep && currentStep.name) {
            case 'cancel':
                return !getters.getIntakeCancelReason.length > 0;
            case 'accountHolderSearch':
                return !getters.getSelectedCustomer.length > 0;
            case 'accountSelect':
                return !getters.getSelectedAccount.length > 0;
            case 'reason':
                return !getters.getReasonFormValidity;
            case 'transactionSelection':
                return getters.getTransactionsStatus.transactionsListDisplay === 'required' && getters.getSelectedTransactions.length === 0;
            case 'questionnaire':
                return !getters.getQuestionnaireFormValidity;
            case 'requiredDocs':
                return false;
            case 'summaryQuestionnaire':
                return !getters.getSummaryQuestionnaireFormValidity;
            case 'confirmation':
                break;
        }

        return true;
    },
    getIntakeNavigationNextButtonHidden: (state, getters) => { return getters.getIntakeNavigationCurrentStep && getters.getIntakeNavigationCurrentStep.hideNextButton },
    getIntakeNavigationNextButtonLabel: (state, getters) => { return getters.getIntakeNavigationCurrentStep && getters.getIntakeNavigationCurrentStep.nextButtonLabel || 'Next' },
    getIntakeNavigationNextButtonLoading: (state, getters) => {
        const currentStep = getters.getIntakeNavigationCurrentStep;
        const pendingCalls = getters.getPendingCallList;

        switch (currentStep && currentStep.name) {
            case 'cancel':
                return pendingCalls.includes("/claim/intake/cancel");
            case 'accountHolderSearch':
                return pendingCalls.includes("/claim/intake/retrieveAccounts");
            case 'accountSelect':
                return pendingCalls.includes("/claim/intake/create") || pendingCalls.includes("/claim/intake/retrieve") || pendingCalls.includes("/claim/intake/retrieveReasons");
            case 'reason':
                return pendingCalls.includes("/claim/intake/addClaimReasons") || pendingCalls.includes("/claim/intake/retrieveTransactions");
            case 'transactionSelection':
                return pendingCalls.includes("/claim/intake/addTransactions") || pendingCalls.includes("/claim/intake/retrieveQuestionnaire");
            case 'questionnaire':
                return pendingCalls.includes("/claim/intake/addQuestionnaire") || pendingCalls.includes("/claim/intake/retrieveDocRequirements");
            case 'requiredDocs':
                return pendingCalls.includes("/claim/intake/addDocuments") || pendingCalls.includes("/claim/intake/retrieveSummary") || pendingCalls.includes("/claim/intake/retrieveCorrespondenceChannels");
            case 'summaryQuestionnaire':
                return pendingCalls.includes("/claim/intake/submit") || pendingCalls.includes("/claim/intake/retrieveConfirmation");
            case 'confirmation':
                break;
        }

        return false;
    },
    getQuestionnaireFormValidity: (state) => { return state.questionnaireFormValid },
    getReasonFormValidity: (state) => { return state.reasonFormValid },
    getSummaryQuestionnaireFormValidity: (state) => { return state.summaryQuestionnaireFormValid },
};
